import './TopLevelUiBanner.css';
export const TopLevelBanner = ({ message, actionText, onActionClick }: {message: string, actionText?: string, onActionClick?: ()=>void}) => {
    return (
        <div className="offer-banner">
            <div className="offer-message">
                {message}
            </div>
            {actionText && onActionClick && (
                <button className="offer-action" onClick={onActionClick}>
                    {actionText}
                </button>
            )}
        </div>
    );
};