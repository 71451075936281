import psPlatformClient from "../psPlatformClient";
import {TimeIndex} from "../ps-models";
import {
  LineItemsStore, LineItemsStoreDto,
  LineItemsStorePersistence,
  LineItemStoreFilter,
  PersistenceQuery, QueryResult
} from "../ps-models/lineitems-store";
import {LineItemStoreLogs, LineItemStoreLogsDto} from "../ps-models/LineItemStoreLogs";

export function buildLineItemsStorePersistence() {
  return new RESTLineItemsStorePersistence();
}


let CACHE: Record<string, LineItemsStore> = {}

export function clearLineItemsStoreCache() {
  CACHE = {};
}

class RESTLineItemsStorePersistence implements LineItemsStorePersistence {
  createLineItemsStore(timeIndex: TimeIndex): LineItemsStore {
    return new LineItemsStore(timeIndex);
  }

  async loadLineItemsStore(collection: string, id: string, createNonExistentStore: boolean = true): Promise<LineItemsStore> {
    const result = await psPlatformClient<null, any>({method: 'get',
      url: `/line-items-store/${collection}/${id}?createNonExistentStore=${createNonExistentStore}`});
    return LineItemsStore.deserialize(result.data);
  }

  async persistLineItemsStore(collection: string, store: LineItemsStore): Promise<void> {
    await psPlatformClient<any, null>({method: 'post', url: `/line-items-store/${collection}`,
      data: store.serialize(),
      headers: {'Content-Type': 'application/json'}});
  }

  async query(collection: string, query: PersistenceQuery, customCompanyId?: string): Promise<LineItemsStore> {
    let cacheKey = collection + `-` + JSON.stringify(query);
    if(CACHE[cacheKey]){
      return CACHE[cacheKey]
    }

    console.time('PROF query')
    let res = await psPlatformClient<{query: any}, {store: LineItemsStoreDto, logs: LineItemStoreLogsDto}>({
      method: 'post',
      url: `/line-items-store/${collection}/persistence/query`,
      data: {query: query.serialize(),
      ...(customCompanyId ? {companyId: customCompanyId}: {})
      },
      headers: {'Content-Type': 'application/json'}
    });
    console.timeEnd('PROF query')

    CACHE[cacheKey] = LineItemsStore.deserialize({...res.data.store, logs: res.data.logs});
    return CACHE[cacheKey];
  }

  async deleteStore(collection: string, id: string): Promise<void> {
    await psPlatformClient<any, any>({method: 'delete', url: `/line-items-store/${collection}/${id}`});
  }
}


