import {format, formatDistance} from "date-fns";
import {NavLink, useHistory} from "react-router-dom";
import {useCompany, useCompanyId, usePlatformUrls} from "../../core";
import {
  AmDashboard,
  AmInvoice,
  AMModuleNames,
  AmProject, extractProjectAndVersionIdFromStoreId,
  getStoreId
} from "../../ps-types";
import {buildEntityDef, EntityCrud} from "../../ui/crud/EntityCrud.component";
import {getAmInvoices, saveAmInvoice} from "./amInvoice.client";
import {addTime, formatDateByTimeUnit, formatValueWithValueType, getAmProjectConfig} from "../../ps-models";
import {getAmDashboards} from "../builder/Dashboard.client";
import React, {useEffect, useState} from "react";
import {getAmProjects} from "../projects/amProject.client";
import {LoadingBlock} from "../../ui/Loading";
import {ValueType} from "../../ps-models/line-items";
import {TopLevelBanner} from "../../ui/TopLevelUIBanner";


function timeAgo(value: string) {
  return value ? formatDistance(new Date(value), new Date(), {addSuffix: true}) : "";
}

export function InvoiceLink({invoice}: {invoice: AmInvoice}) {
  const { amInvoiceDetails } = usePlatformUrls();
  return<NavLink to={() => amInvoiceDetails(invoice.id)}>{invoice.name}</NavLink>
}

export function Invoices() {
  const history = useHistory();

  const companyId = useCompanyId();
  let company = useCompany();
  const { namespace } = getAmProjectConfig(company);
  const amNamespace: AMModuleNames = namespace as AMModuleNames;
  let [companyReportTemplates, setCompanyReportTemplates] = useState<AmDashboard[]>();
  const [projects, setProjects] = useState<AmProject[]>();
  const isNotSharedReadOnlyMode = namespace !== 'ESG'

  useEffect(()=>{
    getAmDashboards(companyId).then((results)=>{
      setCompanyReportTemplates(results?.filter((d)=>d.exposedAsInjectionTemplate && d.injectionTemplateConfig?.type === "Invoice"));
    })
    if(isNotSharedReadOnlyMode){
      getAmProjects(companyId).then((results)=>{
        setProjects(results.filter((pr)=>pr?.versions.length !==0));
      })
    }
  }, [companyId, isNotSharedReadOnlyMode])

  const getEntities = () => getAmInvoices(companyId);
  const createEntity =  async (data: any) => {
    let reportPeriod = '';

    if(addTime(data.dateRange.from, 1, 'months').getTime() === addTime(data.dateRange.to, 1, 'days').getTime()){
      reportPeriod = formatDateByTimeUnit(data.dateRange.from, 'months');
    } else if(addTime(data.dateRange.from, 1, 'quarters').getTime() === addTime(data.dateRange.to, 1, 'days').getTime()){
      reportPeriod = formatDateByTimeUnit(data.dateRange.from, 'quarters');
    } else if(addTime(data.dateRange.from, 1, 'years').getTime() === addTime(data.dateRange.to, 1, 'days').getTime()){
      reportPeriod = formatDateByTimeUnit(data.dateRange.from, 'years');
    }
    const dashboard = companyReportTemplates?.find((template)=>template.id === data.dashboardId);
    if(!dashboard) throw new Error('Report does not have a dashboard');
    if(!reportPeriod) throw new Error('Unsupported date range selected');
    // @TODO: Accept this as array
    if(!data.storeIds) throw new Error('Please select a project');
    let invoiceConfig = {dateRange: data.dateRange, storeIds: [data.storeIds] }
    let invoiceName = `${dashboard.injectionTemplateConfig?.name || `${dashboard.name}`}-${reportPeriod}-${getProjectNameForInvoice(invoiceConfig, projects)}`
    invoiceName = invoiceName.replace(/ /g, '-');
    return await saveAmInvoice(companyId, invoiceName, data.dashboardId, invoiceConfig);
  }

  let def = buildEntityDef(
    {
      entityName: "Invoice",
      title: "My Invoices",
    ...(isNotSharedReadOnlyMode ? {createEntity}: {}),
      getEntities,
      table:{
        tableComponent: 'dataGrid'
      },
    },
    {
      name: {
        table: {
          render: (_value: string, invoice) =>
            <InvoiceLink invoice={invoice} />
        },
        create: { type: "hidden" },
      },
      dateRange: {
        title: "Invoice Period",
        create: {
          type: "utc-date",
        },
        table: {
          render: (_value: string, invoice) =>
            <>{formatDateByTimeUnit(invoice.config.dateRange.from, 'months')}</>
        }
      },
      storeIds: {
        title: "PROJECT",
        create: {
          type: "select",
          options: projects?.map((pr)=>({key: getStoreId(pr.id, pr?.versions[0].versionId), value: getStoreId(pr.id, pr?.versions[0].versionId), text: pr.name}))
        },
        table: {
          format: (value: string, invoice) => getProjectNameForInvoice(invoice?.config as AmInvoice['config'], projects)
        }
      },
      "data.number": {
        title: "Ref #",
        create: {type: "hidden"},
        table: {
          format: (value) => value ?? "-"
        }
      },
      "data.amount": {
        title: "Amount Billed",
        create: {type: "hidden"},
        table: {
          format: (value) => value ? formatValueWithValueType(value as ValueType, "dollars") : "-"
        }
      },
      "data.dueAmount": {
        title: "Due Amount",
        create: {type: "hidden"},
        table: {
          format: (value) => value ? formatValueWithValueType(value as ValueType, "dollars") : "-"
        }
      },
      "data.dated": {
        title: "Due Date",
        create: {type: "hidden"},
        table: {
          format: (value) => value ?? "-"
        }
      },
      status: {
        create: {type: "hidden"},
      },
      generatedAt: {
        title: "Generated At",
        create: {
          type: "hidden",
        },
        table: {
          render: (value) =>
              <>{value ?  format(value, "dd/MM/yyyy") : "-"}</>
        }
      },
      dashboardId: {
        title: "Invoice Template",
        create: {
          type: 'select',
          options: companyReportTemplates?.map((op)=>({key: op.id, value: op.id, text: op.name})),
        },
        table: {
          render: (value) =>
              <>{companyReportTemplates?.find((c)=>c.id === value)?.name ??  "-"}</>
        }
      },
      createdAt: {
        title: "CREATED",
        create: { type: "hidden" },
        table: { format: timeAgo }
      }
    }
  );

  if (isNotSharedReadOnlyMode && !projects) return <LoadingBlock/>;
  return <div>
    <EntityCrud entityDef={def} />
    {/* @TODO: Move this into companyModule*/}
    {namespace === 'ESG' && <TopLevelBanner
        message={'Upgrade your subscription to leverage the invoicing feature.'}
    />}
  </div>
}

function getProjectNameForInvoice(invoiceConfig: AmInvoice['config'], projects: AmProject[] | undefined){
  return projects?.find(p => p.id === extractProjectAndVersionIdFromStoreId(invoiceConfig?.storeIds?.[0]).projectId)?.name ?? "-"
}