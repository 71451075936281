import {DefaultModule, registerModule} from "../../builder/DashboardConfigServiceRegistry";
import {LineItemsStore} from "../../../ps-models/lineitems-store";
import {STORE_VALUE_DECIMALS_KEY, VALUE_TYPE_KEY} from "../../../ps-models";


registerModule('FourthPartner', new class extends DefaultModule {
    onStoreInit(store: LineItemsStore, dashboardId: string) {
        let itemsWithValueTypeRupee = store.getDataSet().getByField(VALUE_TYPE_KEY, 'rupee');
        itemsWithValueTypeRupee.forEach((item)=>{
            item.fields.addField(STORE_VALUE_DECIMALS_KEY, '0')
        })
    }
})