import React, {ReactElement} from "react";
import {Dropdown, Menu, Segment} from "semantic-ui-react";
import {normalizeString} from "../ps-models";
import {Route, useHistory} from "react-router-dom";
import {useScenarioSelect} from "./scenarios/scnearioSelect";

export type Section = {
  title: string, key: string, path: string,
  render: (selectedStores: string[], compareWithStores: string[]) => ReactElement,
  getPath: () => string,
  isHome?: boolean,
  parent?: string,
  order: number
}

export class SectionService {
  private sections: Record<string, Section> = {};

  constructor(public basePath: string, private companyId: string) {}

  
  setHomeSection(key: string) {
    //Unset home
    Object.values(this.sections).forEach(section => {
      section.isHome = false;
    });
    this.sections[key].isHome = true;

  }
  
  addSection(data: Partial<Section>) {
    const {basePath: BASE_PATH, companyId} = this;

    if (!data.title) throw new Error("Section must have a title");
    if (!data.render) throw new Error("Section must have a render function");

    let title = data.title;
    let key = data.key || normalizeString(data.title.replace(" ", "-"));
    //replace symbols in key
    key = key.replace(/[^a-zA-Z0-9-]/g, "");
    let path = `${BASE_PATH}/${key}`;

    this.sections[key] = {
      title,
      key,
      path,
      ...data,
      render: data.render,
      order: data.order || Object.values(this.sections).length,
      getPath: () => path.replace(":companyId", companyId)
    } as Section;

  }

  getSections() {
    let sections =  Object.values(this.sections);
    return sections.sort((a, b) => {
      return a.order - b.order;
    });

  }

}

function SectionItem({section, as, ...rest}: {section: Section, as: React.ElementType, [_: string]: any}) {
  const history = useHistory();
  const Item = as;
  return <Item {...rest} onClick={() => history.push(section.getPath())}>{section.title}</Item>
}

export function SectionsContent({sectionService}:{sectionService: SectionService}) {

  let homeSection = sectionService.getSections().find(section => section.isHome);

  return  <Segment>
    {homeSection && <Route exact  path={sectionService.basePath}  key={homeSection.key} render={()=> homeSection.render([],[])} />}
    {sectionService.getSections().map(section =>
      <Route exact  path={section.path}  key={section.key}
             render={()=> section.render([],[])} />
    )}

  </Segment>
}

export function SectionsContentWithScenarios({sectionService}:{sectionService: SectionService}) {

  let {component, selectedStores, compareWithStores} = useScenarioSelect();

  return  <Segment>
    {component}
    {sectionService.getSections().map(section =>
      <Route exact  path={section.path}  key={section.key}
             render={()=> section.render(selectedStores, compareWithStores)} />
    )}
  </Segment>
}

export function SectionsMenu({sectionService}:{sectionService: SectionService}) {
  //Get parent titles
  let parentsToRender = new Set(sectionService.getSections()
    .map(section => section.parent));

  //Generate individual items or dropdowns

  let elements: ReactElement[] = [];

  let stuff: string[] = []

  sectionService.getSections().forEach(section => {
    if (!section.parent) {
      elements.push(<SectionItem key={section.key} section={section} as={Menu.Item}/>);
      stuff.push(section.title)
    } else {
      if (!parentsToRender.has(section.parent)) return;
      stuff.push(section.title)
      elements.push(
        <Dropdown item simple text={section.parent} key={section.parent}>
          <Dropdown.Menu>
            {sectionService.getSections().filter(s => s.parent === section.parent)
              .map(s => <SectionItem key={s.key} section={s} as={Dropdown.Item}/>)}
          </Dropdown.Menu>
        </Dropdown>
      )
    }
    parentsToRender.delete(section.parent);
  });

  console.info(stuff)

  return <Menu compact>
    {elements}
  </Menu>
}