import psPlatformClient from "../../../psPlatformClient";
import {AmProjectVersionCreationResponse} from "../../../ps-types";
import {AxiosError, AxiosResponse} from "axios";

export const createEsgPortfolio = async (companyId: string,
                                             excelFile: File,
                                             updateFileTransferProgress?: (percentCompleted: number)=>void
) => {

  if(!excelFile) throw new Error("excelFile must be provided");

  const formDataInstance = new FormData();
  formDataInstance.append("companyId", companyId);
  if(excelFile) formDataInstance.append("file", excelFile);

  return await ESGResponseWrapper<FormData, any>(psPlatformClient<FormData, AmProjectVersionCreationResponse>({
    method: 'post',
    url: `/am/${companyId}/esg/portfolio`,
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
    data: formDataInstance,
    onUploadProgress: ((progressEvent) => {
      if(progressEvent?.total) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
        updateFileTransferProgress && updateFileTransferProgress(percentCompleted);
      }
    })

  }));
}


const ESGResponseWrapper = async <Req, Res>(request: Promise<AxiosResponse<Res, Req>>): Promise<Res> => {
  try {
    const result = await request;
    return result.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response && err.response.status >= 400) {
      if (err.response.data && typeof err.response.data === 'object') {
        const errResponseDescription = err.response.data?.description;
        if (errResponseDescription?.reason) {
          throw new Error(errResponseDescription?.reason);
        }
      }
    }
    throw err;
  }
}
