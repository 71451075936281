import {Button, Form, Grid, Icon, Image, Modal, Segment} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import salesforceData from "./demo-data/Salesforce_DummyData_DemoIntegrations"
import powerFactorsData from "./demo-data/PowerFactors_DummyData_DemoIntegrations"
import netSuiteData from "./demo-data/NetSuite_DummyData_DemoIntegrations"
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {SmallLoading} from "../../ui/Loading";


export function SalesforceIntegrationDemo() {
  return <IntegrationDemo service="Salesforce" logo={"/am-logos/salesforce.png"} sampleData={salesforceData} />
}

export function PowerFactorsIntegrationDemo() {
  return <IntegrationDemo service="Power Factors" logo={"/am-logos/power-factors.png"} sampleData={powerFactorsData} />
}

export function NetSuiteIntegrationDemo() {
  return <IntegrationDemo service="Net Suite" logo={"/am-logos/netsuite.png"} sampleData={netSuiteData}/>
}

const LoginModal = ({service, logo, onLoggedIn}:{logo: string, service:string, onLoggedIn: ()=>void}) => {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogin = () => {
    console.log('Login attempt with:', username, password);
    // Add the logic to authenticate with Salesforce here
    handleClose(); // Close modal after login attempt
    onLoggedIn();
  };

  return (
    <Modal
      trigger={<Button onClick={handleOpen} primary>Connect to {service}</Button>}
      open={open}
      onClose={handleClose}
      size='small'
      dimmer='blurring'
    >
      <Modal.Header>
        <Image src={logo} size='small' style={{ marginRight: '15px', width: 50 }} floated="left" />
        Salesforce Connect
        <Icon name='close' onClick={handleClose} style={{ cursor: 'pointer', float: 'right' }} />
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            fluid
            icon='user'
            iconPosition='left'
            label='Username'
            placeholder='Enter username'
            value={username}
            onChange={(e, { value }) => setUsername(value)}
          />
          <Form.Input
            fluid
            icon='lock'
            iconPosition='left'
            label='Password'
            type='password'
            placeholder='Enter password'
            value={password}
            onChange={(e, { value }) => setPassword(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' onClick={handleClose}>
          Cancel
        </Button>
        <Button color='green' onClick={handleLogin}>
          Login
        </Button>
      </Modal.Actions>
    </Modal>
  );
};



export function IntegrationDemo({logo, sampleData, service}: {logo: string, sampleData: string, service: string}) {

  let [data, setData] = useState<any>()
  let [loading, setLoading] = useState<boolean>(false)
  let [loggedIn, setLoggedIn] = useState<boolean>(false)

  const loadData = () => {
    setLoading(true)
    setTimeout(() => {
      setData(sampleData)
      setLoading(false)
    }, 2000);

  }



  return <Segment>
    <Grid columns={2} verticalAlign="middle">
      <Grid.Column width={3}>
        <Image src={logo} style={{ width: '200px', borderRadius: '2px', padding: 20 }} />
      </Grid.Column>
      <Grid.Column width={13}>
        {!loggedIn && <LoginModal onLoggedIn={()=>setLoggedIn(true)} logo={logo} service={service}  />}
        {loggedIn && <Button primary onClick={loadData}>Load Data</Button>}
        {loggedIn && <Button floated="right" color="grey" onClick={() => setLoggedIn(false)}>Disconnect</Button>}

      </Grid.Column>
    </Grid>
    {loading && <SmallLoading />}
    {data && <CsvDataGrid csvData={data} />}
  </Segment>
}




interface CsvDataGridProps {
  csvData: string;
}

interface DataRow {
  id: number;
  [key: string]: string | number;
}

const CsvDataGrid: React.FC<CsvDataGridProps> = ({ csvData }) => {
  const [rows, setRows] = useState<DataRow[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    parseCsvData(csvData);
  }, [csvData]);

  const parseCsvData = (data: string): void => {
    const lines = data.split('\n').map(line => line.trim()).filter(line => line !== '');
    if (lines.length > 0) {
      const headers = lines[0].split(',').map(header => header.trim());

      // Set columns from headers
      const colDefs: GridColDef[] = headers.map((header, index) => ({
        field: header,
        headerName: header.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
        width: 150,
        type: ['Project_Life_Years', 'Tariff_Per_kWh', 'Initial_Investment', 'PR_Ratio', 'Standard_Temperature_Celsius'].includes(header) ? 'number' : 'string',
      }));
      setColumns(colDefs);

      // Set rows
      const dataRows: DataRow[] = lines.slice(1).map((line, index) => {
        const values = line.split(',');
        let row: DataRow = { id: index };
        headers.forEach((header, i) => {
          row[header] = ['Project_Life_Years', 'Tariff_Per_kWh', 'Initial_Investment', 'PR_Ratio', 'Standard_Temperature_Celsius'].includes(header) ? parseFloat(values[i]) : values[i];
        });
        return row;
      });
      setRows(dataRows);
    }
  };

  return (
    <div style={{ height: 600, width: '100%' }}>
      {columns.length > 0 && rows.length > 0 && (
        <DataGridPro
          rows={rows}
          columns={columns}
        />
      )}
    </div>
  );
};

