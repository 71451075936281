export default `Project_ID,Project_Name,Project_Location,PV_Generation_kWh,Availability_Percentage,,
PJT-0001,North Sydney,North Sydney,502342.21,98.75,,
PJT-0002,Tamworth,Tamworth,453123.78,96.35,,
PJT-0003,Liverpool,Liverpool,522344.56,97.65,,
PJT-0004,Maitland,Maitland,485678.92,99.25,,
PJT-0005,Taree,Taree,474556.33,96.85,,
PJT-0006,Heidelberg,Heidelberg,507894.44,97.45,,
PJT-0007,Project GA,Brisbane,532345.12,98.15,,
PJT-0008,Ballart,Ballarat,451234.67,96.75,,
PJT-0009,Mount Louisa,Mount Louisa,483245.89,98.95,,
PJT-0010,Logan Central,Logan Central,527834.23,97.25,,
PJT-0011,Cowes,Cowes,467892.34,96.65,,
PJT-0012,Torquay,Torquay,507656.22,98.85,,
PJT-0013,Trinity Beach,Trinity Beach,482344.12,97.05,,
PJT-0014,Noosa Heads,Noosa Heads,472890.45,96.45,,
PJT-0015,Nerang,Nerang,495678.98,98.65,,
PJT-0016,Mackay,Mackay,532489.78,97.15,,
PJT-0017,Paddington,Paddington,451234.67,96.95,,
PJT-0018,Project F,Los Angeles,462345.78,98.25,,
PJT-0019,Project A,New York,487654.89,96.75,,
PJT-0020,Project B,Chicago,503456.23,98.55,,
PJT-0021,Project D,Houston,472123.45,96.45,,
PJT-0022,Project G,Phoenix,526789.34,97.85,,
PJT-0023,Project E,Philadelphia,501234.67,97.15,,
PJT-0024,Project C,San Antonio,463234.12,98.45,,
PJT-0025,Project K,San Diego,482341.78,96.85,,
PJT-0026,Project M4,Dallas,531234.56,98.05,,
PJT-0027,Project L,San Jose,451235.67,97.65,,
PJT-0028,Project M,Austin,482345.12,98.75,,
PJT-0029,Project J12,Jacksonville,501234.89,96.25,,
PJT-0030,Project Morro,Fort Worth,467894.33,97.45,,
PJT-0031,Project Tahoe,Columbus,472345.12,96.85,,
PJT-0032,Project Fresno,Charlotte,524567.22,98.15,,
PJT-0033,Project Shashta,Indianapolis,503456.12,97.35,,
PJT-0034,Project Folsom,Seattle,451234.89,98.65,,`