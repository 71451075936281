import {DefaultModule, registerModule} from "../../builder/DashboardConfigServiceRegistry";
import {SectionService} from "../../Section";
import SunwealthHome from "./SunwealthHome";

registerModule('Sunwealth', new class extends DefaultModule {

  overrideDashboardSections(sectionService: SectionService) {

    //Adds home page
    sectionService.addSection({
      key: 'home',
      title: 'Home',
      order: -10,
      render: () => <SunwealthHome />
    });

    sectionService.setHomeSection('home')

    return sectionService;
  }
})