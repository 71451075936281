import {getAmDashboards} from "./Dashboard.client";
import {PS_URLS, useCompany, useCompanyId} from "../../core";
import React, {useEffect, useState} from "react";
import {AMCompanyModel, AmDashboard} from "../../ps-types";
import {SectionsContent, SectionService, SectionsMenu} from "../Section";
import {DashboardBoardView} from "./DashboardView";
import {Button, Container, Grid, Header, Image} from "semantic-ui-react";
import {RefreshBtn} from "../index";
import {getAmProjectConfig} from "../../ps-models";
import {authStorage} from "../../auth";
import {loadCompanyModule} from "./DashboardConfigServiceRegistry";
import {getAmCompanyModelDownloadUrl, getAmCompanyModels} from "../../company/company.client";
import {useMessages} from "../../ui/MessagesProvider";
import {useHistory} from "react-router-dom";
import {TopLevelBanner} from "../../ui/TopLevelUIBanner";

export function DashboardMainPage() {

  let companyId =  useCompanyId();

  let [dashboards, setDashboards] = useState<AmDashboard[]>();
  const company = authStorage.getCompany();
  const { namespace } = getAmProjectConfig(company);
  let companyLogoPath = "/am-logos/FourthPartnerLogo.png";
  if(namespace === "PUBLICDEMO"){
    companyLogoPath = "/logo512.png";
  } else if(namespace === "Ampyr"){
    companyLogoPath = "/am-logos/Ampyr.png";
  } else if(namespace === "KingEnergy"){
    companyLogoPath = "/am-logos/king-energy-logo.png";
  } else if(namespace === "KelvinClearGen"){
    companyLogoPath = "/am-logos/Kelvin.png";
  } else if(namespace === "CGCDemo"){
    companyLogoPath = "/logo512.png"
  } else if(namespace === "ESG"){
    companyLogoPath = "/logo512.png"
  } else if(namespace === "Sunwealth") {
    companyLogoPath = "/logo512.png"
  }


  useEffect(() => {
    getAmDashboards(companyId)
      .then((res) => {
        setDashboards(res);
      });
  }, [companyId]);

  const BASE_PATH = `/ps/:companyId/am/dashboard`;

  const sectionsService = new SectionService(BASE_PATH, companyId);

  dashboards?.filter(d => d.navigationConfig)
    .forEach((d, index) => {
      sectionsService.addSection({
        title: d.navigationConfig!.menuName || d.name,
        key: d.navigationConfig!.key,
        parent: d.navigationConfig!.parentId,
        isHome: index === 0,
        order: d.navigationConfig?.order,
        render: () => {
          return <div>
            {(namespace === 'Sunwealth' && d.companyId !==company.id) && <TopLevelBanner
            message={'Upgrade your subscription to edit this dashboard and evaluate your portfolio performance.'}

            />}
                    <DashboardBoardView
                        key={d.id}
                        dashboardId={d.id} dashboardData={d} />
                  </div>
        }
      });
  });


  let module = loadCompanyModule();

  module.overrideDashboardSections(sectionsService);

  return <>
        <Header>
          <Container>
            <Grid  verticalAlign='middle'>
              {namespace === 'ESG' && <Grid.Row>
                <ESGDashboardButtons companyId={companyId} />
              </Grid.Row>}
              <Grid.Row>
                <Grid.Column width={2}>
                  <div style={{paddingLeft: "20px"}}>
                  <Image width={170} src={companyLogoPath}/>
                </div>
                </Grid.Column>
                <Grid.Column width={12} textAlign="center" >
                  <SectionsMenu sectionService={sectionsService} />
                </Grid.Column>
                <Grid.Column  width={2}>
                  <RefreshBtn  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Header>
        <SectionsContent sectionService={sectionsService}/>
  </>
}

function ESGDashboardButtons({companyId}: {companyId: string}){
  let {clear, error} = useMessages();
  const history = useHistory();
  const [models, setModels] = useState<AMCompanyModel[]>([]);
  useEffect(()=>{
    getAmCompanyModels(companyId).then((res)=>{
      setModels(res);
    }).catch((err)=>{
      console.error(err);
      error("Something went wrong. Please try after sometime.")
    })
  }, [companyId])

  const downloadModel = async (companyModelData: AMCompanyModel) => {
    getAmCompanyModelDownloadUrl(companyId, companyModelData.id).then(async (res) => {
      window.open(res.url, "_blank")
    }).catch((err) => {
      console.error(err);
      error("Unable to download the model at the moment. Please try after sometime.")
    });
  }

  return <div style={{width: '100%'}}>
    <Button primary floated="right" content={"Upload PV Generation Template"} onClick={()=>history.push(PS_URLS(companyId).esgTemplateUpload())}/>
    <Button floated="right" content={'Download Template'} disabled={models?.length === 0} onClick={()=>{
      if(models?.length >0){
        return downloadModel(models?.[0]);
      }
    }}/>
  </div>
}