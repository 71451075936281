export default `
Project_ID,Project_Name,Project_Location,Project_Life_Years,Tariff_Per_kWh,Initial_Investment,PR_Ratio,Standard_Temperature_Celsius
PJT-0001,North Sydney,North Sydney,25,0.0741,6030452,0.79,25
PJT-0002,Tamworth,Tamworth,22,0.0593,4523789,0.82,30
PJT-0003,Liverpool,Liverpool,28,0.0679,8049872,0.78,20
PJT-0004,Maitland,Maitland,24,0.0750,7023456,0.77,25
PJT-0005,Taree,Taree,23,0.0702,5634901,0.81,30
PJT-0006,Heidelberg,Heidelberg,26,0.0603,6853482,0.76,25
PJT-0007,Project GA,Brisbane,29,0.0640,7554900,0.82,20
PJT-0008,Ballart,Ballarat,21,0.0790,4548902,0.74,30
PJT-0009,Mount Louisa,Mount Louisa,27,0.0717,5034502,0.85,25
PJT-0010,Logan Central,Logan Central,25,0.0628,6034800,0.78,20
PJT-0011,Cowes,Cowes,24,0.0678,5943280,0.76,30
PJT-0012,Torquay,Torquay,28,0.0720,6243900,0.81,25
PJT-0013,Trinity Beach,Trinity Beach,23,0.0737,5734500,0.79,20
PJT-0014,Noosa Heads,Noosa Heads,25,0.0637,6437500,0.80,30
PJT-0015,Nerang,Nerang,22,0.0695,5034200,0.78,25
PJT-0016,Mackay,Mackay,26,0.0727,7042300,0.80,20
PJT-0017,Paddington,Paddington,29,0.0661,7132300,0.77,30
PJT-0018,Project F,Los Angeles,28,0.0624,6240000,0.79,25
PJT-0019,Project A,New York,24,0.0668,5832000,0.83,20
PJT-0020,Project B,Chicago,27,0.0699,6654000,0.80,30
PJT-0021,Project D,Houston,21,0.0640,5440000,0.77,25
PJT-0022,Project G,Phoenix,26,0.0730,6230000,0.76,20
PJT-0023,Project E,Philadelphia,29,0.0682,7040000,0.78,30
PJT-0024,Project C,San Antonio,22,0.0658,5250000,0.80,25
PJT-0025,Project K,San Diego,23,0.0707,5630000,0.77,20
PJT-0026,Project M4,Dallas,28,0.0629,6130000,0.78,30
PJT-0027,Project L,San Jose,24,0.0740,6520000,0.76,25
PJT-0028,Project M,Austin,27,0.0687,7230000,0.83,20
PJT-0029,Project J12,Jacksonville,23,0.0674,5840000,0.80,30
PJT-0030,Project Morro,Fort Worth,25,0.0638,6030000,0.79,25
PJT-0031,Project Tahoe,Columbus,22,0.0701,5430000,0.77,20
PJT-0032,Project Fresno,Charlotte,28,0.0677,6730000,0.79,30
PJT-0033,Project Shashta,Indianapolis,26,0.0706,6130000,0.76,25
PJT-0034,Project Folsom,Seattle,24,0.0644,6440000,0.78,20
`