//TODO: Aggregators should support ValueType (string and number)

import {LineItem, ValueType} from "../line-items"
import {compareNormalized, entries, normalizeString} from "./coding.utils";
import {reportLineItemError} from "./ErrorReporting";
import {FinancialValueType} from "../fin-math/FinancialValueTypes";


type NumberAggregator = (values: number[]) => number

export const first: NumberAggregator = (values: number[]) => values[0]
export const last: NumberAggregator = values => values[values.length - 1]
export const lastNZ: NumberAggregator = values => {
    for (let i = values.length - 1; i >= 0; i--) {
        if (values[i] !== 0) {
            return values[i];
        }
    }
    return 0;
}
export const min: NumberAggregator = values => Math.min(...values)
export const max: NumberAggregator = values => Math.max(...values)
export const sum: NumberAggregator = values => values.reduce((a, b) => a + b, 0)
export const avg: NumberAggregator = values => sum(values) / values.length
export const avgNZ: NumberAggregator = values => avg(values.filter(n => n !== 0))
export const firstNZ: NumberAggregator = values => values.find(n => n!==0) ?? 0
export const findIndexFirstPositive: NumberAggregator = values => values.findIndex(n => n > 0)
export const findIndexFirstNegative: NumberAggregator = values => values.findIndex(n => n < 0);

function variance(values: number[]): number {
    const n = values.length;
    if (n < 2) {
        reportLineItemError("Need at least two data points to compute variance");
        return NaN;
    }

    const mean = values.reduce((a, b) => a + b) / n;
    const variance = values.reduce((sum, value) => sum + (value - mean) ** 2, 0) / (n - 1);

    return variance;
}
export function stDev(values: number[]): number {
    return Math.sqrt(variance(values));
}

export const stDevNZ: NumberAggregator = values => {
    return stDev(values.filter(n => n !== 0));
}



export const NumberAggregators = {
    first,
    firstNZ,
    last,
    lastNZ,
    min,
    max,
    sum,
    avg,
    avgNZ,
    stDev,
    stDevNZ,
    findIndexFirstPositive,
    findIndexFirstNegative,
} as const

export type NumberAggregatorMethods = keyof typeof NumberAggregators


export type AggregatorMethod = keyof typeof NumberAggregators | keyof typeof StringAggregators | 'none'
export type AggregatorMap = (li: LineItem) => AggregatorMethod


export const StringAggregators = {
    first: (values: string[]) => values[0],
    last: (values: string[]) => values[values.length - 1],
    concat: (values: string[]) => values.filter(v=>v.trim()).join(", ")
} as const


export function runAggregator(lineItem: LineItem, values: ValueType[], aggregatorMap: (li: LineItem)=>AggregatorMethod): ValueType {
    return runAggregatorMethod(aggregatorMap(lineItem), values)
}


export function runAggregatorMethod(aggregator: AggregatorMethod, values: ValueType[]) {
    if(values && values[0] !== undefined) {
        if(typeof values[0] === "string") {
            let aggregatorMethod = StringAggregators[aggregator as keyof typeof StringAggregators];

            if(aggregatorMethod) {
                return aggregatorMethod(values as string[]);
            }
            return ""
        }
        if(typeof values[0] === "number") {
            let aggregatorMethod = NumberAggregators[aggregator as keyof typeof NumberAggregators];

            if(aggregatorMethod) {
                return aggregatorMethod(values as number[]);
            }
            return 0
        } if(typeof values[0] === "boolean") {
            // @TODO: Work on this to support aggregations
            return values[0]
        }
    }

    return 0
}





export const singleAggregator = (am: AggregatorMethod): AggregatorMap => (li: LineItem) => am


/**
 * @Deprecated use lineItemAggregatorMap instead
 */
export const lineItemAggregatorMapLegacy = (aggregatorMap: Record<string, AggregatorMethod>, defaultAggregator: AggregatorMethod = "sum"): AggregatorMap => (lineItem: LineItem) => {
    aggregatorMap = entries(aggregatorMap).reduce((acc, [k, v]) => ({...acc, [normalizeString(k)]: v}), {});

    return aggregatorMap[normalizeString(lineItem.canonicalName)]   || defaultAggregator
}

//Usage { "lineItemName": "sum", "sName:y": "avg" }
export const lineItemAggregatorMap = (aggregatorMap: Record<string, AggregatorMethod>, defaultAggregator?: AggregatorMethod): AggregatorMap => (lineItem: LineItem) => {
    aggregatorMap = entries(aggregatorMap).reduce((acc, [k, v]) => ({...acc, [normalizeString(k)]: v}), {});

    return aggregatorMap[normalizeString(lineItem.canonicalName)] || defaultAggregator
}
//Usage { "fieldName:fieldValue": "sum", "sName:y": "avg" }
export const fieldAggregatorMap =
  (aggregatorMap: Record<string, AggregatorMethod>, defaultAggregator: AggregatorMethod = "sum"): AggregatorMap =>
    (lineItem: LineItem) => {
    aggregatorMap = entries(aggregatorMap).reduce((acc, [k, v]) => ({...acc, [normalizeString(k)]: v}), {})

    for (let matcher of Object.keys(aggregatorMap)) {
        let [fieldName, fieldValue] = matcher.split(":");

        //Check by special field (Type, Name, etc)
        if( (fieldName === "$type" && compareNormalized(lineItem.type, fieldValue)) ||
          (fieldName === "$name" && compareNormalized(lineItem.name, fieldValue))
        ) {
            return aggregatorMap[matcher]
        }


        let value = lineItem.fields.getFieldStr(fieldName);
        if (value && compareNormalized(value, fieldValue)) {
            return aggregatorMap[matcher]
        }
    }

    return aggregatorMap[normalizeString(lineItem.name)] || defaultAggregator
}

export const composeAggregatorMap = (...maps: AggregatorMap[]): AggregatorMap => (lineItem: LineItem) => {
    return maps.find(m => m(lineItem) !== undefined)?.(lineItem) || "sum"
}
export const DEFAULT_AGGREGATOR_MAP = composeAggregatorMap(fieldAggregatorMap({
    "$type:ParameterLineItem": "first",
    "store_valuetype:string": "last",
    "store_valuetype:number": "sum",
    "store_valuetype:dollars": "sum"
}))

export function getDefaultAggregationMethodForValueType(valueType: FinancialValueType): AggregatorMethod {
    switch (valueType){
        case "percentX100":
        case "percent":
        case "percentage":
        case "multipleOf":
            return "avg";
        case "zip":
        case "date":
        case "utc_mmddyyyy":
        case "utc_mmyyyy":
        case "utc_mmm-yy":
        case "utc_ddmmyy":
        case "string":
        case "bool":
            return "last"
        default:
            return "sum";
    }
}