import React, {useState} from "react";
import {Button, Form, Modal} from "semantic-ui-react";
import {FormDef} from "./types";
import {Field} from "./common/Field";
import {assocPath, path} from "ramda";

export function CreateEntity<F extends FormDef>({
                                                         fields,
                                                         entityName,
                                                         options,
                                                         onEntityCreated,
                                                         createEntity}: FormDef) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<Record<string, any>>(()=>
      mergeRelevantKeysUnderParent(Object.fromEntries(
          Object.keys(fields).filter((k)=>fields[k].type !=='hidden').map(key => [key, fields[key].defaultValue])
      ))
  );

  options = {
    buttonText: `Add ${entityName}`,
    modalTitle: `Create new ${entityName}`,
    buttonDisabled: options?.buttonDisabled || false,
    ...options};

  const handleCreate = () => {
    createEntity(formData)
      .then((data: any) => {
        onEntityCreated && onEntityCreated(data);
      });

    setOpen(false);
  }

  return <>
    <Button disabled={options.buttonDisabled} primary size="tiny" onClick={() => setOpen(true)}>{options.buttonText}</Button>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>{options.modalTitle}</Modal.Header>
        <Modal.Content>
          <Form
              onSubmit={e => {
            e.preventDefault();
          }}>
          {Object.values(fields).map(fieldDef =>
            <Field fieldDef={fieldDef} entityName={entityName}
                   key={fieldDef.key}
                   value={path(fieldDef.key.split("."), formData)}
                   onChange={ value => setFormData( f => assocPath(fieldDef.key.split("."), value, f))}
            />
            )}
          </Form>

        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button
            primary
            type='submit'
            content="Create"
            labelPosition='right'
            icon='checkmark'
            onClick={() => handleCreate()}
          />

        </Modal.Actions>

      </Modal>
  </>
}

function mergeRelevantKeysUnderParent(incomingObject: {[key: string]: any}): {[key: string]: any} {
  const result: {[key: string]: any} = {};

  Object.keys(incomingObject).forEach((key) => {
    const keyParts = key.split('.');
    if(keyParts.length === 1) {
      result[key] = incomingObject[key];
    } else {
      const parentKey = keyParts[0];
      const childKey = keyParts[1];

      if (!result[parentKey]) {
        result[parentKey] = {};
      }

      result[parentKey][childKey] = incomingObject[key];
    }
  });

  return result;
}

