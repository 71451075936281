import {AMModuleNames, AmProjectConfig, Company} from "../ps-types";


export const DEFAULT_AM_PROJECT_CONFIG: AmProjectConfig = {
    namespace: 'DEMO',
    collection: 'DEMO_LineItems'
}

export const ESG_SHARED_COMPANY_AM_PROJECT_CONFIG: AmProjectConfig = {
    namespace: 'CGCDemo',
    collection: "ESG_SharedCompany_LineItems"
}

export const getAmProjectConfig = (company: Company): AmProjectConfig => {
    const amProjectId = company?.amProjectId as string ?? 'DEMO';
    return company?.amProjectConfig ?? {
        namespace: amProjectId,
        collection: `${amProjectId}_LineItems`
    };
}