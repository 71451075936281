import {formatDistance} from "date-fns";
import {NavLink, useHistory} from "react-router-dom";
import {PS_URLS, useCompany, useCompanyId, usePlatformUrls} from "../../core";
import {AmDashboard, AMModuleNames, AmReport} from "../../ps-types";
import {DeleteButton} from "../../ui/crud/DeleteButton.component";
import {buildEntityDef, EntityCrud} from "../../ui/crud/EntityCrud.component";
import {createAmReport, deleteAmReport, getAmReports} from "./amReports.client";
import {addTime,formatDateByTimeUnit, getAmProjectConfig} from "../../ps-models";
import {getAmDashboards} from "../builder/Dashboard.client";
import React, {useEffect, useState} from "react";
import {TopLevelBanner} from "../../ui/TopLevelUIBanner";


function timeAgo(value: string) {
  return value ? formatDistance(new Date(value), new Date(), {addSuffix: true}) : "";
}

export function ReportLink({report}: {report: AmReport}) {
  const { amReportDetails } = usePlatformUrls();
  return<NavLink to={() => amReportDetails(report.id)}>{report.name}</NavLink>
}

function DeleteBtn({entityName, entity, handleDelete}: {entityName: string, entity: any, handleDelete: (entity: any) => void}) {
  const content =`Are you sure you want to delete the ${entityName} '${entity["name"]}'?`;

  return <DeleteButton
    content={content}
    onDelete={() => handleDelete(entity)}
  />
}


export function Reports() {
  const history = useHistory();

  const companyId = useCompanyId();
  let company = useCompany();
  const { namespace } = getAmProjectConfig(company);
  const amNamespace: AMModuleNames = namespace as AMModuleNames;
  let [companyReportTemplates, setCompanyReportTemplates] = useState<AmDashboard[]>();

  useEffect(()=>{
    getAmDashboards(companyId).then((results)=>{
      // @TODO: Change the condition to check for is of type Report, once migration is done for existing report dashboards.
      setCompanyReportTemplates(results?.filter((d)=>d.exposedAsInjectionTemplate && d.injectionTemplateConfig?.type !== "Invoice"))
    })
  }, [companyId])

  const getEntities = () => getAmReports(companyId);
  const createEntity =  async (data: any) => {
    let reportPeriod = '';

    if(addTime(data.dateRange.from, 1, 'months').getTime() === addTime(data.dateRange.to, 1, 'days').getTime()){
      reportPeriod = formatDateByTimeUnit(data.dateRange.from, 'months');
    } else if(addTime(data.dateRange.from, 1, 'quarters').getTime() === addTime(data.dateRange.to, 1, 'days').getTime()){
      reportPeriod = formatDateByTimeUnit(data.dateRange.from, 'quarters')
    } else if(addTime(data.dateRange.from, 1, 'years').getTime() === addTime(data.dateRange.to, 1, 'days').getTime()){
      reportPeriod = formatDateByTimeUnit(data.dateRange.from, 'years');
    }

    const dashboard = companyReportTemplates?.find((template)=>template.id === data.dashboardId);
    if(!dashboard) throw new Error('Report does not have a dashboard');
    if(!reportPeriod) throw new Error('Unsupported date range selected');
    let reportName = `${dashboard.injectionTemplateConfig?.name || `${dashboard.name}`}-${reportPeriod}`
    reportName = reportName.replace(/ /g, '-');
    return await createAmReport(companyId,
        reportName,
        data.dashboardId,
      {dateRange: data.dateRange})
  }

  const deleteEntity = (project: any) => deleteAmReport(companyId, project.id);

  let def = buildEntityDef(
    {
      entityName: "Report",
      title: "My Reports",
      createEntity,
      getEntities,
      deleteEntity,
      table: {
        deleteButton: (props)=> (<DeleteBtn {...props} />)
      }
    },
    {
      name: {
        table: {
          render: (_value: string, report) =>
            <ReportLink report={report} />
        },
        create: { type: "hidden" },
      },
      dateRange: {
        title: "PERIOD",
        create: {
          type: "utc-date",
          // defaultValue: {from: utcDate(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1), to : utcDate(new Date().getUTCFullYear(), new Date().getUTCMonth()+1, 0)}
        },
      },
      dashboardId: {
        title: "Report Template Id",
        create: {
          type: 'select',
          options: companyReportTemplates?.map((op)=>({key: op.id, value: op.id, text: op.name})),
        }
      },
      createdAt: {
        title: "CREATED",
        create: { type: "hidden" },
        table: { format: timeAgo }
      }
    }
  );

  return <div>
    <EntityCrud entityDef={def} />
    {/* @TODO: Move this into companyModule*/}
    {namespace === 'ESG' && <TopLevelBanner
        message={'Upgrade your subscription to leverage the reporting feature.'}
    />}
  </div>
}