import {ReactElement} from "react";
import {v4 as uuidv4} from "uuid";
import {LineItemsStore, PersistenceQuery} from "../../ps-models/lineitems-store";
import {WidgetConfig, WidgetConfigBase} from "./WidgetConfig";
import {DashboardConfig} from "./DashboardConfig";
import {DashboardConfigService} from "./DashboardConfigService";

const WIDGET_REGISTRY: { [key: string]: WidgetRegistryEntry } = {};

export function registerWidgetType(entry: WidgetRegistryEntry) {
  if(WIDGET_REGISTRY[entry.typeId]) {
    console.warn(`Widget with id ${entry.typeId} already registered`);
  }
  WIDGET_REGISTRY[entry.typeId] = entry;
}

export function getWidgetTypes() {
  return Object.keys(WIDGET_REGISTRY).map(key => WIDGET_REGISTRY[key]);
}

export function getWidgetType(typeId: string) {
  if(!WIDGET_REGISTRY[typeId]) {
    console.warn(`Widget with id ${typeId} not registered`);
  }
  return WIDGET_REGISTRY[typeId];
}

export type BuilderContext = {
  appContext: DashboardConfigService,
  query: PersistenceQuery,
  readOnly: boolean,
  params: Record<string, any>,
  allParams: Record<string, any>,
  dashboardConfig: DashboardConfig,
  globalContext: any
} & any

export interface WidgetRegistryEntry {
  typeId: string,
  metadata: {
    name: string,
    description: string,
    icon: string
  },
  defaultConfig: any,
  renderConfig: (config: any, context: BuilderContext, onConfigChange: (config: any, machineName?: string ) => void) => ReactElement,
  render: (config: any, context: any, setOutput: (key: string, value: any) => void ) => ReactElement,
  preRender?: (context: BuilderContext, config?: any) => void
}

export function buildWidgetConfig(id: string, type: string, config: any, parent: string = 'root') {
  id = (id === '$auto') ? `${type}-${uuidv4().substring(0, 4)}` : id;
  return new WidgetConfig(id, type, config, parent);
}

export function buildWidgetConfigBase(id: string, type: string, config: any, machineName: string) {
  return new WidgetConfigBase(id, type, config, machineName);
}