import React from 'react';
import {Container, Header, Segment, Button, List, Icon, Grid, SegmentGroup} from 'semantic-ui-react';
import {useHistory} from "react-router-dom";
import {useCompanyId} from "../../../core";

const Dashboard = () => {
  const companyId = useCompanyId()



  return (
    <Container>
      <Segment>

      {/*<Segment placeholder>*/}
      {/*  <Header as='h4'>Please watch this brief video to learn how to extract the most value out of Perl Street for Impact Reporting and more.</Header>*/}
      {/*  /!* Assuming you have a video component or placeholder here *!/*/}
      {/*</Segment>*/}

      <Header as='h2' color="purple">Active Subscriptions</Header>
      <SegmentGroup divided relaxed>
          <Subscription
            title="Portfolio Impact Reporting"
            description="Report on your ESG Impact across portfolios."
            url={`/ps/${companyId}/am/dashboard/impact-summary`}
          />
          <Subscription
            title="Portfolio Rollups"
            description="Aggregate financial and operational performance across portfolios."
            url={`/ps/${companyId}/am/dashboard/project-cashflows`}
          />
          <Subscription
            title="Investor and Management Reporting"
            description="Provide granular view/edit access to specific portfolios for dynamic reporting that saves time."
            url={`/ps/${companyId}/am/dashboard/executive-sumary`}
          />
          <Subscription
            title="Invoicing"
            description="Manage customer invoicing - including automatic updates to/via most major Accounting/ERP software."
            url={`/ps/${companyId}/am/invoices`}
          />

          <Subscription
            title="Payments"
            description="Take control of payments via Perl Street payment integrations."
            url={`/ps/${companyId}/am/invoices`}
          />

          <Subscription
            title="Loan and Borrowing Base Management"
            description="Manage your debt schedules and borrowing base reporting across projects and portfolios."
            url={`/ps/${companyId}/am/dashboard/debt-obligations`}
          />

      </SegmentGroup>
      </Segment>
    </Container>
  );
};

interface SubscriptionProps {
  title: string;
  description: string;
  active?: boolean;
  url?: string;
}

const Subscription: React.FC<SubscriptionProps> = ({ title, description, active, url }) => {

  const history = useHistory();
  const handleClick = () => {
    history.push(url!)
  };

  return (
    <Segment style={{ boxShadow: 'none' }}>
      <Grid columns={2} verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as='h3' style={{fontSize: 18}}>
              {active && <Icon name='check circle outline' color='green' size='small' />}
              {title}
            </Header>
            <p>{description}</p>
          </Grid.Column>
          <Grid.Column width={6} textAlign='right'>
            <Button primary style={{ marginRight: '30px' }}
              onClick={handleClick}
            >See It in Action</Button>
            {/*<Button secondary={active} disabled={!active}>Upgrade</Button>*/}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
export default Dashboard;
