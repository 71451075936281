import {HeadingWidget} from "./HeadingWidget";
import {ColumnsWidget} from "./ColumnsWidget"
import {BuilderContext, buildWidgetConfigBase, getWidgetTypes, WidgetRegistryEntry} from "../../WidgetRegistry";
import {v4 as uuidv4} from "uuid";
import {Config, Data} from "@measured/puck";
import {WidgetConfigContextProvider, WidgetContextProvider} from "../../common-components/WidgetContextWrapper";
import {DashboardConfigService} from "../../DashboardConfigService";
import {CSSProperties, ReactNode, useMemo} from "react";

interface PSWidgetConfigObject {
    machineName: string;
    config: any;
    id: string;
    context?: BuilderContext;
}
export const DASHBOARD_COMPONENTS_CONFIG: Config = {
    components: {
        ColumnsWidget,
        HeadingWidget,
        ...(getWidgetTypes()
                .filter((w) => w.typeId !== 'Container') // Skipping Container as it is deprecated in favour of ColumnsWidget.
                .map((w) => {
                    return {
                        [w.typeId]: {
                            label: w.metadata.name,
                            ...w,
                            defaultProps: {
                                config: w.defaultConfig,
                                machineName: `${w.typeId}-${uuidv4().substring(0, 4)}`
                            },
                            fields: {
                                machineName: {type: "text", label: "Machine Name"},
                                config: {
                                    type: "custom", render: ({onChange, value}: any) => {
                                        if(value === undefined){ // This is to handle unselecting of widget
                                            return <></>
                                        }
                                        return <WidgetConfigContextProvider
                                            render={(context) => <>{w.renderConfig(value, context, onChange)}</>}
                                        />
                                    }
                                },
                            } as Record<keyof PSWidgetConfigObject, any>,
                            render: (props: PSWidgetConfigObject & any) => {
                                return <WidgetContextProvider
                                    render={(context) => <WidgetRenderer widgetProps={props} widgetEntry={w} context={{...context, ...props?.context}} />
                                }
                                />
                            }
                        }
                    }
                }).reduce((w1, w2) => ({...w1, ...w2}), {})
        )
    },
}

const WidgetRenderer = ({widgetEntry, widgetProps, context}:{widgetEntry: WidgetRegistryEntry, widgetProps: PSWidgetConfigObject & any, context: BuilderContext})=>{

    const widgetConfig = useMemo(()=>{
        return buildWidgetConfigBase(widgetProps.id, widgetEntry.typeId, widgetProps.config, widgetProps.machineName)
    }, [widgetProps.id, widgetProps.config, widgetEntry.typeId, widgetProps.machineName])

    const widgetContext = useMemo(()=>{
        let ctx = widgetConfig.buildWidgetContext(context);
        let machineName = widgetConfig.machineName;
        return {
            ...ctx,
            params: (ctx && ctx.widgetParams && ctx.widgetParams[machineName]) || {}
        }
    }, [widgetConfig, context])
    if(context.readOnly && (widgetEntry.typeId !== 'HeadingWidget' && widgetEntry.typeId !== 'ColumnsWidget')){
        return widgetContext.appContext.renderWidget({config: widgetProps, context: widgetContext, widgetId: widgetProps.id})
    } else {
        return <>{widgetEntry.render(widgetProps, widgetContext, (key, val) => {
            widgetConfig.setOutput(key, val);
        })}</>
    }
}

export const extractPuckDataFromDashboardConfig = (dashboardService: DashboardConfigService, context?: BuilderContext): Data => {
    return {content:Object.values(dashboardService.getConfig().getWidgets()).map((it)=>it.serialize()).map((item)=>{
        return {type: item.type, props: {config:item.config, machineName: item.machineName, id: item.id, ...(context ? {context}: {})} }
    }), ...dashboardService.getConfig().getLayout()}
}

export type SectionProps = {
    className?: string;
    children: ReactNode;
    padding?: string;
    maxWidth?: string;
    style?: CSSProperties;
};

export const WidgetWrapperSection = ({
                                         children,
                                         className,
                                         padding = "0px",
                                         maxWidth = "1280px",
                                         style = {},
                                     }: SectionProps) => {
    return (
        <div
            className={`widget-section${className ? ` ${className}` : ""}`}
            style={{
                ...style,
                paddingTop: padding,
                paddingBottom: padding,
            }}
        >
            <div className={'widget-section inner'} style={{ maxWidth }}>
                {children}
            </div>
        </div>
    );
};