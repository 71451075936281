export default `Project_ID,Project_Name,Project_Location,Expense_Category,Buyout_Values,Currency_Type,Operating_Expenses,Revenue_Per_kWh,Total_Revenue,FCFF,Capital_Expenditure,Net_Income
PJT-0001,North Sydney,North Sydney,Operational Expenses,1204500,USD,203450,0.10,1504500,503450,103450,253450
PJT-0002,Tamworth,Tamworth,Operational Expenses,1503400,USD,184320,0.12,1705600,454320,153420,304320
PJT-0003,Liverpool,Liverpool,Operational Expenses,1008900,USD,223450,0.11,2003200,603450,183450,403450
PJT-0004,Maitland,Maitland,Operational Expenses,1310000,USD,193200,0.09,1803200,553200,143200,283200
PJT-0005,Taree,Taree,Operational Expenses,1258900,USD,213450,0.11,1906700,523450,153450,323450
PJT-0006,Heidelberg,Heidelberg,Operational Expenses,1400000,USD,173450,0.12,1750000,530000,133450,293450
PJT-0007,Project GA,Brisbane,Operational Expenses,1353400,USD,185000,0.13,1855000,563200,125450,313450
PJT-0008,Ballart,Ballarat,Operational Expenses,1224500,USD,200000,0.09,1623450,473450,143450,273450
PJT-0009,Mount Louisa,Mount Louisa,Operational Expenses,1530000,USD,210320,0.11,1920000,553200,183450,333450
PJT-0010,Logan Central,Logan Central,Operational Expenses,1268900,USD,234500,0.10,1758000,543200,163450,303450
PJT-0011,Cowes,Cowes,Operational Expenses,1334500,USD,184320,0.09,1650000,533450,173450,293450
PJT-0012,Torquay,Torquay,Operational Expenses,1373450,USD,223450,0.10,1953000,603450,143450,323450
PJT-0013,Trinity Beach,Trinity Beach,Operational Expenses,1405000,USD,203450,0.12,1850000,523450,183450,313450
PJT-0014,Noosa Heads,Noosa Heads,Operational Expenses,1450000,USD,173200,0.11,1812000,553450,153450,303450
PJT-0015,Nerang,Nerang,Operational Expenses,1264500,USD,193450,0.13,1723000,533200,163450,283450
PJT-0016,Mackay,Mackay,Operational Expenses,1214500,USD,203200,0.10,1612340,473450,143200,263450
PJT-0017,Paddington,Paddington,Operational Expenses,1320000,USD,223450,0.11,1802000,503450,173450,303450
PJT-0018,Project F,Los Angeles,Operational Expenses,1374500,USD,193450,0.12,1767000,523450,163450,293450
PJT-0019,Project A,New York,Operational Expenses,1405000,USD,213450,0.09,1910000,563200,153450,313450
PJT-0020,Project B,Chicago,Operational Expenses,1268900,USD,203450,0.10,1663000,533450,133450,283450
PJT-0021,Project D,Houston,Operational Expenses,1460000,USD,234500,0.12,1962000,603450,143450,333450
PJT-0022,Project G,Phoenix,Operational Expenses,1334500,USD,183200,0.11,1760000,543450,183450,303450
PJT-0023,Project E,Philadelphia,Operational Expenses,1410000,USD,213450,0.10,1867000,553450,173450,293450
PJT-0024,Project C,San Antonio,Operational Expenses,1224500,USD,193200,0.09,1622000,473200,153450,273450
PJT-0025,Project K,San Diego,Operational Expenses,1358900,USD,203450,0.12,1812000,523200,143450,293450
PJT-0026,Project M4,Dallas,Operational Expenses,1264500,USD,173450,0.11,1663000,533450,133450,263450
PJT-0027,Project L,San Jose,Operational Expenses,1320000,USD,223200,0.10,1812340,563450,173450,313450
PJT-0028,Project M,Austin,Operational Expenses,1410000,USD,203450,0.12,1867000,553450,153450,283450
PJT-0029,Project J12,Jacksonville,Operational Expenses,1450000,USD,213450,0.13,1915000,533450,183450,333450
PJT-0030,Project Morro,Fort Worth,Operational Expenses,1212340,USD,183450,0.11,1732000,523450,163450,283450
PJT-0031,Project Tahoe,Columbus,Operational Expenses,1324500,USD,203450,0.09,1770000,543200,153450,303450
PJT-0032,Project Fresno,Charlotte,Operational Expenses,1268900,USD,193200,0.12,1663000,473450,143450,273450
PJT-0033,Project Shashta,Indianapolis,Operational Expenses,1374500,USD,223450,0.10,1812000,563200,173450,323450
PJT-0034,Project Folsom,Seattle,Operational Expenses,1450000,USD,203450,0.13,1910000,553200,143450,303450`